#mc_embed_signup {
  background-color: rgba(0, 0, 0, 0) !important;
  font-size: 11pt;
}

.footer-list-wrapper {
  margin-top: auto;
  margin-left: 1rem;
  position: absolute;
  bottom: 0;
}

.footer-list ul {
  text-align: center;
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 1rem;
}

.footer-list ul li {
  display: inline-flex;
  list-style-type: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0.85rem;
}

.footer-list ul li a {
  align-self: center;
  color: var(--red);
}

.footer-list ul li a:hover {
  opacity: 0.5;
}

.privacy-policy {
  font-size: 6pt !important;
  color: var(--red);
}

.footerHeader {
  color: var(--red) !important;
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 10pt;
  /* opacity: 10%; */
}
.spotify-follow {
  padding-top: 0.5rem;
}

.hidden-div {
  z-index: 1;
  padding-top: 1rem;
  opacity: 0.2;
  /* background-color: var(--white); */
  /* transition: 0.5s opacity; */
  /* animation: pulse-hidden 10s infinite;
    -webkit-animation: pulse-hidden 10s infinite; */
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}

.hidden-div:hover {
  opacity: 1 !important;
  /* transition: 0.2s opacity; */
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation: none;
  -webkit-animation: none;
}

#mc_embed_signup input.email {
  width: 50% !important;
  /* margin-bottom: 1rem !important; */
  height: 20px;
  line-height: 20px;
  font-size: 8pt;
}

/* .subscribe {
  background-color: #ebebf200;
} */

/* .subscribe:hover {
  background-color: #ebebf2;
} */

#mc_embed_signup .button {
  background-color: var(--red);
  height: 20px;
  font-size: 8pt;
  line-height: 20px;
  width: 100px;
}

#mc_embed_signup .button:hover {
  background-color: var(--lilac);
}

.icon-footer {
  height: 20px;
}

.icon-footer:hover {
  opacity: 20% !important;
}

@keyframes pulse-hidden {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.6;
  }
}

@media only screen and (min-width: 300px) and (max-width: 991px) {
  .footer {
    position: relative;
    margin-top: 3rem;
  }

  .hidden-div {
    opacity: 0.8;
  }

  .footer-list-wrapper {
    margin-top: auto;
    position: relative;
    margin-left: 0rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1452px) {
  .footer-list ul li {
    padding: 0.22rem;
  }
}

.right-footer-list ul {
  text-align: center;
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 0rem;
}

.right-footer-list ul li {
  display: inline-flex;
  list-style-type: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0rem;
}

.right-footer-list ul li a {
  align-self: center;
}

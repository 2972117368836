body {
  margin: 0;
  font-family: "Lora", serif;
  color: var(--white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --red: #ffab5c;
  --white: #eeeeeb;
  --white-faded: #eee6eb52;
  --yellow: #d2c459;
  --green: #ffab5c;
  --blue: #ffab5c;
  --black: #000000;
  --lilac: #687575;
  --body-font: "Lora", serif;
}

#root {
  background-color: var(--black);
  background-image: url("./media/images/AD-art-no-text.jpg");

  /* background-image: url("./media/images/tour-background-rot.jpg"); */
  /* background-size: cover; */
  background-size: 100% auto;
  /* overflow: hidden; */
  /* background-repeat: no-repeat; */
}

html {
  background-color: var(--black);
  background-size: 100% auto;
  background-image: url("./media/images/AD-art-no-text.jpg");

  /* background-image: url("./media/images/tour-background-rot.jpg"); */
  /* background-size: 100% auto; */

  /* overflow: hidden; */
  /* background-image: url("./media/images/mixture-comp.jpg"); */
  /* background-repeat: repeat; */
}

@media (max-width: 740px) {
  #root {
    background-size: auto 100vh;
    background-image: url("./media/images/AD-art-no-text.jpg");
  }
  html {
    background-size: auto 100vh;
    background-image: url("./media/images/AD-art-no-text.jpg");
  }
}

/* @media (prefers-color-scheme: light) {
  :root {
    --black: #eeeeeb;
    --white: #1a1519;
    --white-faded: #1a15196b;
    --blue: #056c53;
    --green: #056c53;
    --red: #056c53;
  }
  #root {
    background-image: url("./media/images/tour-background-inverted.jpg");
  }

  html {
    background-image: url("./media/images/tour-background-inverted.jpg");
  }
} */

.img-wrapper-song-page {
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.listen-quote-es {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--blue) !important;
  font-family: "Lora", serif;
}

.listen-quote-es a:hover {
  color: var(--blue) !important;
}

.listen-quote-es a {
  color: var(--red) !important;
}

.song-page-subtitle {
  font-size: 1.5rem;
  font-family: "Lora", serif;
  color: var(--blue);
  font-weight: 600;
  padding-top: 3rem;
}

.song-page-subsubtitle {
  font-size: 1.5rem;
  font-family: "Lora", serif;
  color: var(--red);
  font-weight: 400;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.song-page-subsubsubtitle {
  font-size: 1.2rem;
  font-family: "Lora", serif;
  color: var(--red);
  font-weight: 600;
  font-style: italic;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.raise {
  z-index: 10;
  position: relative;
}

.listen-link-song-page {
  /* margin: 1rem; */
  padding: 0.5rem;
  font-family: "Lora", serif;
  color: var(--black);
  font-weight: 400;
  font-size: 1rem;
  /* border: solid var(--lilac); */
  background-color: var(--blue);
  border-width: 3px 3px;
  border-radius: 5px 5px;
}

.listen-link-song-page:hover {
  color: var(--red) !important;
  text-decoration: none !important;
  background-color: #9a94a944;
}

#youtube-player {
  margin-top: 2rem !important;
}

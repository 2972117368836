.h2-main {
  color: var(--white);
  font-family: var(--body-font);
  font-size: 1.3rem;
}

.home-link {
  color: var(--black);
  font-family: var(--body-font);
  font-size: 1.3rem;
  background-color: var(--white);
  padding: 5px;
  border-radius: 10px;
}

.home-link:hover {
  text-decoration: none;
  color: var(--green);
}

.home-navlink:hover {
  text-decoration: none;
  color: var(--red);
}

.home-navlink {
  color: var(--white);
}

.tour-header-home {
  font-family: "Lora", serif;
  letter-spacing: 0.5rem;
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--white);
}

.announce-header-home {
  font-family: "Lora", serif;
  letter-spacing: 0.2rem;
  font-weight: 900;
  font-size: 1.3rem;
  color: var(--white);
}

.home-divider {
  border: 1px solid var(--red);
}

.ticket-button {
  color: var(--green);
  height: 3em !important;
  width: 3em !important;
}

.ticket-button:hover {
  color: var(--white);
  height: 3em !important;
  width: 3em !important;
}

.hidden-text {
  display: none;
  font-family: "Lora", serif;
  /* letter-spacing: 0.5rem; */
  font-weight: 600;
  font-size: 1rem;
  color: var(--white);
}

.ticket-button:hover + .hidden-text {
  display: block;
}

.home-img{
  width: 40%;
}

.home-button {
  /* margin: 1rem; */
  z-index: 100;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  font-family: "Lora", serif;
  color: var(--white);
  font-weight: 400;
  font-size: 1.5rem;
  /* border: solid var(--lilac); */
  background-color: rgba(255, 255, 255, 0.1);
  border-width: 3px 3px;
  border-radius: 5px 5px;
}

.home-button:hover {
  color: var(--white) !important;
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  cursor: pointer;
}

.img-label{
  color: var(--white) !important;
}

.listen-link{
    padding:1rem;
    font-family: 'Lora', serif;
    color: var(--red);
    font-weight: 400;
    font-size: 1rem;
}

.listen-link:hover{
    color: var(--white) !important;
    text-decoration: underline !important;
}

.listen-subtitle{
    font-family: 'Lora', serif;
    color: var(--red);
    font-weight: 400;
    font-size: 1.5rem;
    padding-top:1rem;
}

.navbar-nav {
  width: 100%;
  justify-content: center;
  background: var(--white);
  /* background-image: url("../../media/images/mixture-comp.png"); */
  background-clip: text;
  background-attachment: fixed;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}

.title-logo {
  max-width: 100%;
  max-height: 15rem;
  /* max-height: 15rem; */
  /* width: auto; */
}

.navbar-nav .safari_only {
  -webkit-text-fill-color: var(--white);
}

.row .nav {
  width: 100%;
  margin: 0;
}

.nav-item {
  padding: 0.5rem;
}

.nav-link a {
  font-size: 1.2rem;
  /* font-family: 'Cormorant', serif; */
  /* font-family: 'Roboto Slab', serif; */
  font-family: "Lora", serif;
  color: var(--white);
  font-weight: 400;
}

.nav-link a:hover {
  /* font-weight: 800; */
  text-decoration: none;
  transition: 0.5s color;
  color: var(--red);
  -webkit-text-fill-color: var(--red);
}

.nav-title:hover {
  cursor: pointer;
}

.nav-title a:hover {
  color: var(--red);
  text-decoration: none;
  -webkit-text-fill-color: var(--red);
}

.navbar-divider {
  border-top: 2px solid #b46c6e57;
  border-radius: 3px;
  margin: 1rem 6rem 0 6rem;
}

.navbar-wrapper {
  margin-top: 2rem;
}

.navbar-toggler {
  background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-height: 850px) and (max-width: 450px) {
  .nav-title a {
    -webkit-text-fill-color: var(--white) !important;
    /* cursor: grab; */
  }

  .nav-link a {
    -webkit-text-fill-color: var(--white) !important;
    /* cursor: grab; */
  }
}

.preorder {
  background-color: var(--white) !important;
  font-weight: 800;
  opacity: 0.75;
  color: var(--black);
  border: none;
  text-decoration: none;
  border-radius: 2px;
  font-size: 1.5rem;
}

.preorder:hover {
  opacity: 1;
}
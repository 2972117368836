.img-wrapper-es {
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.listen-quote-es {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--blue) !important;
  font-family: "Lora", serif;
}

.listen-quote-es a:hover {
  color: var(--blue) !important;
}

.listen-quote-es a {
  color: var(--red) !important;
}

.links-subtitle {
  font-size: 1.5rem;
  font-family: "Lora", serif;
  color: var(--white);
  font-weight: 600;
}

.links-subsubtitle {
  font-size: 0.75rem;
  font-family: "Lora", serif;
  color: var(--red);
  font-weight: 400;
  /* padding-top: 3rem; */
}

.raise {
  z-index: 10;
  position: relative;
}

.links-button {
  /* margin: 1rem; */
  z-index: 100;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  font-family: "Lora", serif;
  color: var(--white);
  font-weight: 400;
  font-size: 1.5rem;
  /* border: solid var(--lilac); */
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 3px 3px;
  border-radius: 5px 5px;
}

.links-button:hover {
  color: var(--white) !important;
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  cursor: pointer;
}

#youtube-player {
  margin-top: 2rem !important;
}

.links-link {
  font-size: 1.5rem;
  font-family: "Lora", serif;
  color: var(--white);
  font-weight: 600;
}

.links-link:hover {
  color: var(--red) !important;
  cursor: pointer;
  text-decoration: underline;
}

.links-icon {
  font-size: 3rem !important;
  font-family: "Lora", serif;
  color: var(--white);
  font-weight: 600;
}

.links-icon:hover {
  color: var(--red) !important;
  cursor: pointer;
}
